import { Alert, TextField, List, ListItem } from '@mui/material'
import React, { useEffect, useState } from 'react'
import MeasurementsForm from '../measurement-form/MeasurementsForm'
import PropTypes from 'prop-types'
import 'react-dropdown/style.css'
import styles from './CarePlansForm.module.scss'
import ClinicvuService from 'services/Clinicvu/Clinicvu.service'
import { useNotification } from '@context/NotificationContext'

const CarePlansForm = ({
  btnFunction,
  carePlan,
  currentOrganization,
  fetchAction
}) => {
  const [name, setName] = useState('')
  const [activities, setActivities] = useState([])
  const [questionnaires, setQuestionnaires] = useState(null)
  const [files, setFiles] = useState(null)
  const { showNotification } = useNotification()

  console.log('questionnaires', questionnaires)
  console.log('files', files)
  console.log('carePlan', carePlan)

  const nonUniqueReqFlag =
    new Set(activities.map((r) => r.detail.code.coding[0].code)).size !==
    activities.length

  const fetchQuestionnaires = async (organization) => {
    try {
      const res = await ClinicvuService.getAllQuestionnaires(organization)
      setQuestionnaires(res.data)
    } catch (e) {
      showNotification(`Error fetching surveys: ${e.message}`, 'error')
    }
  }

  const fetchFiles = async (organization) => {
    try {
      const res = await ClinicvuService.getAllFiles(organization)
      setFiles(res.data)
    } catch (e) {
      showNotification(`Error fetching files: ${e.message}`, 'error')
    }
  }

  useEffect(() => {
    if (carePlan) {
      setName(carePlan?.title || '')
      setActivities(carePlan?.activity || [])
    }
  }, [carePlan])

  useEffect(() => {
    if (currentOrganization) {
      fetchQuestionnaires(currentOrganization)
      fetchFiles(currentOrganization)
    }
  }, [currentOrganization])

  const addNewRequirement = (e) => {
    e.preventDefault()

    const newActivity = {
      detail: {
        code: {
          coding: [
            {
              code: '56342008'
            }
          ]
        },
        status: 'scheduled',
        scheduledTiming: {
          repeat: {
            frequency: 1,
            periodUnit: 'd'
          }
        }
      }
    }

    const newActivities = [...activities, newActivity]

    setActivities(newActivities)
  }

  const removeActivity = (index) => {
    const newActivities = [...activities]
    newActivities.splice(index, 1)
    setActivities(newActivities)
  }

  const handleActivityChange = (val, index, param) => {
    const newActivities = [...activities]
    if (val <= 0) val = 1

    const paramParts = param.split('.')
    const activity = { ...newActivities[index] }

    let current = activity
    for (let i = 0; i < paramParts.length - 1; i++) {
      current[paramParts[i]] = current[paramParts[i]] || {}
      current = current[paramParts[i]]
    }
    current[paramParts[paramParts.length - 1]] = val

    newActivities[index] = activity
    console.log(newActivities)
    setActivities(newActivities)
  }

  const handleCustomActivityChange = (val, index, param, key) => {
    const newActivities = [...activities]
    if (val <= 0) val = 1
    newActivities[index] = {
      ...newActivities[index],
      [param]: {
        ...newActivities[index][param],
        [key]: val
      }
    }
    setActivities(newActivities)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (name && activities.length > 0) {
      try {
        await ClinicvuService.createCarePlan(
          name,
          activities,
          currentOrganization
        )
        showNotification('Care plan created successfully', 'success')
        btnFunction(null, false)
        fetchAction()
      } catch (e) {
        const errorMessage =
          e.response?.data?.message || e.message || 'Unknown error occurred'
        showNotification(`Error creating care plan: ${errorMessage}`, 'error')
      }
    } else if (!name) {
      showNotification('Your care plan must have a name.', 'error')
    } else if (activities.length === 0) {
      showNotification(
        'Your care plan must have at least one requirement.',
        'error'
      )
    } else {
      showNotification('Unknown error when creating care plan', 'error')
    }
  }

  return (
    <div>
      <div className={styles.header}>
        {carePlan ? 'View Care Plan' : 'New Care Plan'}
      </div>
      <form onSubmit={handleSubmit}>
        <div className={styles.row}>
          <TextField
            label="Care Plan Name"
            disabled={carePlan !== null}
            value={name}
            onChange={(e) => setName(e.target.value)}
            size="small"
            style={{ marginLeft: '25px' }}
          />
        </div>
        <div className={styles.requirements}>
          {activities?.map((activity, index) => (
            <MeasurementsForm
              key={index}
              activity={activity}
              handleActivityChange={handleActivityChange}
              handleCustomActivityChange={handleCustomActivityChange}
              index={index}
              questionnaires={questionnaires}
              removeActivity={removeActivity}
              carePlan={carePlan}
              files={files}
            />
          ))}
        </div>

        {carePlan?.careTeamDetails && (
          <div className={styles.details}>
            <h3>Care Team</h3>
            <List>
              {carePlan.practitionerDetails.map((practitioner, index) => (
                <ListItem key={index}>
                  {`${practitioner.name[0].family}, ${practitioner.name[0].given?.join(' ')}`}
                </ListItem>
              ))}
            </List>
          </div>
        )}

        {carePlan?.patientDetails && (
          <div className={styles.details}>
            <h3>Patients</h3>
            <List>
              {carePlan.patientDetails.map((patient, index) => (
                <ListItem key={index}>
                  {`${patient.name[0].family}, ${patient.name[0].given?.join(' ')}`}
                </ListItem>
              ))}
            </List>
          </div>
        )}

        {nonUniqueReqFlag && (
          <Alert severity="info">
            Please remove or edit all duplicate activity types
          </Alert>
        )}

        <div className={styles['btns-row']}>
          {!carePlan
            ? (
            <button
              disabled={!!carePlan?.id}
              onClick={addNewRequirement}
              className={`${styles.save__btn} ${styles['no-margin-left']}`}
            >
              Add Activity
            </button>
              )
            : null}
          <div>
            {!carePlan && (
              <button
                className={styles['btn-cancel']}
                onClick={() => btnFunction(null, false)}
              >
                {carePlan ? 'Back' : 'Cancel'}
              </button>
            )}
            {!carePlan
              ? (
              <button
                onClick={handleSubmit}
                className={styles.save__btn}
                disabled={nonUniqueReqFlag || !!carePlan?.id}
              >
                Save
              </button>
                )
              : null}
          </div>
        </div>
      </form>
    </div>
  )
}

CarePlansForm.propTypes = {
  btnFunction: PropTypes.func,
  carePlan: PropTypes.object,
  currentOrganization: PropTypes.string,
  fetchAction: PropTypes.func
}

export default CarePlansForm
