import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import ClinicvuService from 'services/Clinicvu/Clinicvu.service'
import styles from './FileDetails.module.scss'
import FileForm from '@shared/file-form/FileForm'
import { useNotification } from 'context/NotificationContext'
import TopSection from '@shared/top-section/TopSection'

const FileDetails = () => {
  const { fileId } = useParams()
  const navigate = useNavigate()
  const [file, setFile] = useState(null)
  const [loading, setLoading] = useState(true)
  const { showNotification } = useNotification()

  useEffect(() => {
    const fetchFile = async () => {
      try {
        const res = await ClinicvuService.getFile(fileId)
        if (res.status !== 200) {
          throw new Error('Failed to fetch file')
        }
        setFile(res.data)
      } catch (err) {
        showNotification(err.message, 'error')
      } finally {
        setLoading(false)
      }
    }

    fetchFile()
  }, [fileId, showNotification])

  if (loading) return <div>Loading...</div>

  const navigateBack = () => {
    navigate('/files')
  }

  return (
    <div className={styles.fileDetails}>
      <TopSection btnFunction={navigateBack} showNew={true}></TopSection>
      <FileForm file={file} />
    </div>
  )
}

export default FileDetails
