/* eslint-disable multiline-ternary */
// todo: re-add the appointments table
// todo: patients are being grouped based on their sign up date, this should later be modified to earliest association time with careplan.

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip
} from '@mui/material'

import TopSection from '@shared/top-section/TopSection'
import { generateMonthlyPatientsGraphData } from '@utils/functions'
import * as _ from 'lodash'
import moment from 'moment'
import React, { useContext, useState, useEffect } from 'react'
import { Line } from 'react-chartjs-2'
import { useNavigate, useLocation } from 'react-router-dom'

import PractitionerForm from '@shared/practitioner-form/PractitionerForm'
import styles from './Clinic.module.scss'

import { CurrentOrganizationContext } from '@context/CurrentOrganization'
import { UserContext } from '@context/UserContext'
import ClinicvuService from 'services/Clinicvu/Clinicvu.service'

const Clinic = () => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [showNew, setShowNew] = useState(false)

  const { currentOrganization } = useContext(CurrentOrganizationContext)
  const { me } = useContext(UserContext)
  const location = useLocation()
  const navigate = useNavigate()

  const isManager = true

  useEffect(() => {
    toggleEditOrNew(null, false)
  }, [location.key])

  const handlePageChange = (e, newPage) => {
    setPage(newPage)
  }

  const handleRowsPerPageChange = (e) => {
    const newRowsPerPage = parseInt(e.target.value, 10)
    setRowsPerPage(newRowsPerPage)
    setPage(0)
  }
  const toggleEditOrNew = (_, toggle) => {
    setShowNew(toggle)
  }

  const [clinicvuUsersOnPortal, setClinicvuUsersOnPortal] = useState([])

  const fetchClinicvuUsersOnPortal = async () => {
    if (currentOrganization) {
      try {
        const res =
          await ClinicvuService.getOrganizationPractitioners(
            currentOrganization
          )
        if (res.status !== 200) {
          throw new Error(
            `Was not able to fetch clinicvu users data for portal ${currentOrganization}`
          )
        }
        setClinicvuUsersOnPortal(res.data)
      } catch (error) {
        console.error(error)
      }
    }
  }

  useEffect(() => {
    fetchClinicvuUsersOnPortal()
  }, [currentOrganization])

  const currClinicvuUsersOnPortals =
    clinicvuUsersOnPortal?.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    ) || []

  const [patients, setPatients] = useState([])

  const fetchPatients = async () => {
    if (me) {
      try {
        const res =
          await ClinicvuService.getPatientsOnOrganization(currentOrganization)
        if (res.status !== 200) {
          throw new Error(
            `Was not able to fetch all patients on portal ${currentOrganization}`
          )
        }
        setPatients(res.data)
      } catch (error) {
        console.error(error)
      }
    }
  }

  useEffect(() => {
    fetchPatients()
  }, [me, currentOrganization])

  const signUpTimes = patients?.map((patient) => patient.created_at) || []

  const monthlyPatients =
    _.chain(
      _.groupBy(signUpTimes, (date) => {
        const st = moment(date).startOf('month')
        return moment(st).format('MMM-YYYY').toLocaleUpperCase()
      })
    )
      .map((time, i) => ({
        month: i,
        patientsArr: time
      }))
      .sortBy((item) => moment(item.month, 'MMM-YYYY'))
      .value() || {}

  const cumulativeMonthlyPatients = monthlyPatients?.map(
    ((sum) => (mp) => {
      sum += mp?.patientsArr?.length
      return {
        month: mp.month,
        totalPatients: sum
      }
    })(0)
  )

  const handleClinicianClick = (practitioner) => {
    toggleEditOrNew(_, true)
    navigate(`/practitioner/${practitioner.id}`)
  }

  const headerText = 'New Practitioner'
  return (
    <div className={styles.content}>
      <TopSection
        isDisabled={!isManager}
        btnFunction={toggleEditOrNew}
        showNew={showNew}
      ></TopSection>
      {!showNew ? (
        patients && (
          <div>
            <div className={styles.tiles}>
              <div className={styles.tile}>
                <img
                  className={styles.tile__icon}
                  src="/assets/svgs/patients.svg"
                  alt="Patients"
                />
                <div className={styles.tile__content}>
                  <div className={styles.tile__header}>All Patients</div>
                  <div className={styles.tile__text}>{patients.length}</div>
                </div>
              </div>
            </div>

            <div className={`${styles.tile} ${styles['tile--large']}`}>
              <div className={styles['tile__header--large']}>
                Your Clinic - Month by Month
              </div>
              <div className={styles.tile__graph}>
                <Line
                  options={{ maintainAspectRatio: false }}
                  data={() =>
                    generateMonthlyPatientsGraphData(cumulativeMonthlyPatients)
                  }
                />
              </div>
            </div>

            <div className={styles.margin}></div>

            <TableContainer component={Paper}>
              <Table
                aria-label="ClinicVU Users on Portal Table"
                className={styles.table}
                stickyHeader
              >
                <TableHead className={styles.table__head}>
                  <TableRow>
                    <TableCell width="20%">Name</TableCell>
                    <TableCell width="30%">Email</TableCell>
                    {isManager && <TableCell width="5%"></TableCell>}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {currClinicvuUsersOnPortals?.map(
                    (clinicvuUserOnPortal, index) => (
                      <TableRow
                        className={styles.tablerow}
                        key={index}
                        onClick={() =>
                          handleClinicianClick(clinicvuUserOnPortal)
                        }
                      >
                        <TableCell>
                          {clinicvuUserOnPortal.name?.[0]?.given?.join(' ') ||
                            'NA'}{' '}
                          {clinicvuUserOnPortal.name?.[0]?.family || 'NA'}
                        </TableCell>
                        <TableCell>
                          {clinicvuUserOnPortal.telecom?.find(
                            (t) => t.system === 'email'
                          )?.value || 'NA'}
                        </TableCell>
                        {isManager && (
                          <TableCell>
                            <Tooltip title="Edit ClinicVU User">
                              <button
                                className={styles.buttons__btn}
                                onClick={(e) => {
                                  e.stopPropagation()
                                  handleClinicianClick(clinicvuUserOnPortal)
                                }}
                              >
                                <img src="/assets/svgs/pencil.svg" alt="Edit" />
                              </button>
                            </Tooltip>
                          </TableCell>
                        )}
                      </TableRow>
                    )
                  )}
                </TableBody>

                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleRowsPerPageChange}
                      page={page}
                      onPageChange={handlePageChange}
                      count={clinicvuUsersOnPortal?.length || 0}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
        )
      ) : (
        <>
          <div className={styles.header}>{headerText}</div>
          <PractitionerForm btnFunction={() => {}} practitioner={null} />
        </>
      )}
    </div>
  )
}

export default Clinic
