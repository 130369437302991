import React, { useEffect, useState, useContext } from 'react'
import CarePlansForm from '@shared/care-plans-form/CarePlansForm'
import { useParams, useNavigate } from 'react-router-dom'
import ClinicvuService from 'services/Clinicvu/Clinicvu.service'
import { useNotification } from '@context/NotificationContext'
import styles from './CarePlanDetails.module.scss'
import TopSection from '@shared/top-section/TopSection'
import { CurrentOrganizationContext } from '@context/CurrentOrganization'

const CarePlanDetails = () => {
  const { carePlanId } = useParams()
  const navigate = useNavigate()
  const [carePlan, setCarePlan] = useState(null)
  const { showNotification } = useNotification()
  const { currentOrganization } = useContext(CurrentOrganizationContext)

  const fetchCarePlan = async (carePlanId) => {
    try {
      const res = await ClinicvuService.getCarePlan(carePlanId)
      setCarePlan(res.data)
    } catch (e) {
      showNotification(`Error fetching care plan: ${e.message}`, 'error')
    }
  }

  useEffect(() => {
    if (carePlanId) fetchCarePlan(carePlanId)
  }, [carePlanId])

  const navigateBack = () => {
    navigate('/care-plans')
  }

  return (
    <div className={styles.content}>
      <TopSection btnFunction={navigateBack} showNew={true}></TopSection>
      <CarePlansForm
        setShowNew={false}
        carePlan={carePlan}
        currentOrganization={currentOrganization}
      />
    </div>
  )
}

export default CarePlanDetails
