/* eslint-disable multiline-ternary */
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip
} from '@mui/material'
import SurveyForm from '@shared/survey-form/SurveyForm'
import TopSection from '@shared/top-section/TopSection'
import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styles from './Questionnaire.module.scss'
import { CurrentOrganizationContext } from '@context/CurrentOrganization'
import ClinicvuService from 'services/Clinicvu/Clinicvu.service'

const Questionnaires = () => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [allQuestionnaires, setAllQuestionnaires] = useState([])
  const [questionnaires, setQuestionnaires] = useState([])
  const [showNew, setShowNew] = useState(false)
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState(null)

  const { currentOrganization } = useContext(CurrentOrganizationContext)
  const location = useLocation()
  const navigate = useNavigate()

  const fetchQuestionnaires = async () => {
    if (currentOrganization) {
      try {
        const res =
          await ClinicvuService.getAllQuestionnaires(currentOrganization)
        setAllQuestionnaires(res.data)
        setPage(0)
      } catch (e) {
        console.error(
          `Error occurred while fetching questionnaires on portal: ${e}`
        )
      }
    }
  }

  useEffect(() => {
    fetchQuestionnaires()
  }, [currentOrganization])

  useEffect(() => {
    setQuestionnaires(allQuestionnaires.slice(0, rowsPerPage))
  }, [allQuestionnaires, rowsPerPage])

  useEffect(() => {
    toggleEditOrNew(null, false)
  }, [location.key])

  const handlePageChange = (e, newPage) => {
    setPage(newPage)
    setQuestionnaires(
      allQuestionnaires.slice(
        newPage * rowsPerPage,
        newPage * rowsPerPage + rowsPerPage
      )
    )
  }

  const handleRowsPerPageChange = (e) => {
    const newRowsPerPage = parseInt(e.target.value, 10)
    setRowsPerPage(newRowsPerPage)
    setPage(0)
    setQuestionnaires(allQuestionnaires.slice(0, newRowsPerPage))
  }

  // eslint-disable-next-line no-unused-vars
  const deleteQuestionnaire = async (name) => {
    if (currentOrganization) {
      try {
        await ClinicvuService.deleteSurvey(currentOrganization, name)
        console.log(`Questionnaire ${name} was deleted .`)
      } catch (e) {
        console.error(
          `Error occurred while deleting questionnaire with the name: ${name}`
        )
      }
    }
  }

  const toggleEditOrNew = (questionnaire, toggle) => {
    setSelectedQuestionnaire(questionnaire)
    setShowNew(toggle)
  }

  const handleQuestionnaireClick = (questionnaire) => {
    navigate(`/questionnaires/${questionnaire.id}`) // Navigate to the desired route
  }

  return (
    <div className={styles.content}>
      <TopSection btnFunction={toggleEditOrNew} showNew={showNew}></TopSection>
      {!showNew ? (
        <div>
          <div className={styles.header}>Questionnaires</div>
          <div className={styles.container}>
            <TableContainer component={Paper}>
              <Table aria-label="Questionnaires Table" className={styles.table}>
                <TableHead className={styles.table__head}>
                  <TableRow>
                    <TableCell width="90%">Name</TableCell>
                    <TableCell width="5%"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {questionnaires.map((questionnaire, index) => (
                    <TableRow
                      className={styles.tablerow}
                      key={index}
                      onClick={() => handleQuestionnaireClick(questionnaire)}
                    >
                      <TableCell>{questionnaire.title}</TableCell>
                      <TableCell>
                        <div className={styles.buttons}>
                          <Tooltip title="View Questionnaire">
                            <button className={styles.buttons__btn}>
                              <img src="/assets/svgs/pencil.svg" alt="Edit" />
                            </button>
                          </Tooltip>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleRowsPerPageChange}
                      page={page}
                      onPageChange={handlePageChange}
                      count={allQuestionnaires.length}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
        </div>
      ) : (
        <div>
          <SurveyForm
            btnFunction={toggleEditOrNew}
            survey={selectedQuestionnaire}
            portal={currentOrganization}
            fetchAction={fetchQuestionnaires}
          />
        </div>
      )}
    </div>
  )
}

export default Questionnaires
