import React, { useEffect, useState } from 'react'
import PractitionerForm from '@shared/practitioner-form/PractitionerForm'
import { useParams, useNavigate } from 'react-router-dom'
import ClinicvuService from 'services/Clinicvu/Clinicvu.service'
import { useNotification } from '@context/NotificationContext'
import styles from './PractitionerDetails.module.scss'
import TopSection from '@shared/top-section/TopSection'

const PractitionerDetails = () => {
  const { practitionerId } = useParams()
  const navigate = useNavigate()
  const [practitioner, setPractitioner] = useState(null)
  const { showNotification } = useNotification()

  const fetchPractitioner = async (practitionerId) => {
    try {
      const res = await ClinicvuService.getPractitioner(practitionerId)
      setPractitioner(res.data)
    } catch (e) {
      showNotification(`Error fetching practitioner: ${e.message}`, 'error')
    }
  }

  useEffect(() => {
    if (practitionerId) fetchPractitioner(practitionerId)
  }, [practitionerId])

  const navigateBack = () => {
    navigate('/clinic')
  }

  return (
    <div className={styles.content}>
      <TopSection btnFunction={navigateBack} showNew={true}></TopSection>
      {practitioner && (
        <PractitionerForm btnFunction={false} practitioner={practitioner} />
      )}
    </div>
  )
}

export default PractitionerDetails
