import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import ClinicvuService from 'services/Clinicvu/Clinicvu.service'
import { useNotification } from '@context/NotificationContext'
import styles from './QuestionnaireDetails.module.scss'
import TopSection from '@shared/top-section/TopSection'
import SurveyForm from '@shared/survey-form/SurveyForm'

const QuestionnaireDetails = () => {
  const { questionnaireId } = useParams()
  const navigate = useNavigate()
  const [questionnaire, setQuestionnaire] = useState(null)
  const { showNotification } = useNotification()

  const fetchQuestionnaire = async (questionnaireId) => {
    try {
      const res = await ClinicvuService.getQuestionnaire(questionnaireId)
      setQuestionnaire(res.data)
    } catch (e) {
      showNotification(`Error fetching questionnaire: ${e.message}`, 'error')
    }
  }

  useEffect(() => {
    if (questionnaireId) fetchQuestionnaire(questionnaireId)
  }, [questionnaireId])

  const navigateBack = () => {
    navigate('/questionnaires')
  }

  return (
    <div className={styles.content}>
      <TopSection btnFunction={navigateBack} showNew={true}></TopSection>
      {questionnaire && <SurveyForm survey={questionnaire} />}
    </div>
  )
}

export default QuestionnaireDetails
