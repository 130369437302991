import React, { useContext, useEffect, useState } from 'react'
import {
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Modal,
  TextField,
  Typography
} from '@mui/material'

import { CurrentOrganizationContext } from '@context/CurrentOrganization'
import PropTypes from 'prop-types'

import styles from './PractitionerForm.module.scss'
import ClinicvuService from 'services/Clinicvu/Clinicvu.service'
import { isValidEmail } from 'utils/functions'

const confirmationModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
}

const PractitionerForm = ({ btnFunction, practitioner }) => {
  const [firstName, setFirstName] = useState(
    practitioner?.name?.[0]?.given?.[0] || ''
  )
  const [lastName, setLastName] = useState(
    practitioner?.name?.[0]?.family || ''
  )
  const [email, setEmail] = useState(
    practitioner?.telecom?.find((t) => t.system === 'email')?.value || ''
  )
  const [role, setRole] = useState(
    practitioner?.roles?.[0]?.resource?.code?.[0].coding?.[0]?.code || ''
  )

  const [carePlanToBeUnselected, setCarePlanToBeUnselected] = useState(null)
  const [selectedCarePlans, setSelectedCarePlans] = useState([])

  const [emailInvalid, setEmailInvalid] = useState(false)
  const [firstNameInvalid, setFirstNameInvalid] = useState(false)
  const [lastNameInvalid, setLastNameInvalid] = useState(false)
  const [roleInvalid, setRoleInvalid] = useState(false)
  const [attempted, setAttempted] = useState(false)

  const { currentOrganization } = useContext(CurrentOrganizationContext)

  const [carePlans, setCarePlans] = useState([])

  const fetchCarePlans = async () => {
    if (currentOrganization) {
      try {
        const res = await ClinicvuService.getCarePlans(currentOrganization)
        if (res.status !== 200) {
          throw new Error(
            `Was not able to fetch careplans data for portal ${currentOrganization}`
          )
        }
        setCarePlans(res.data)
      } catch (error) {
        console.error(error)
      }
    }
  }

  useEffect(() => {
    fetchCarePlans()
  }, [currentOrganization])

  useEffect(() => {
    setSelectedCarePlans(practitioner?.carePlans || [])
  }, [practitioner])

  useEffect(() => {
    if (attempted) {
      setFirstNameInvalid(firstName === '')
      setLastNameInvalid(lastName === '')
      setRoleInvalid(role === '')
      setEmailInvalid(!isValidEmail(email))
    } else if (email !== '') {
      setEmailInvalid(!isValidEmail(email))
    }
  }, [email, attempted, firstName, lastName, role])

  const savePractitionerInfo = async (e) => {
    e.preventDefault()
    setAttempted(true)
    if (
      isValidEmail(email) &&
      firstName !== '' &&
      lastName !== '' &&
      role !== ''
    ) {
      try {
        const res = practitioner
          ? await ClinicvuService.updatePractitioner(
            practitioner.id,
            selectedCarePlans
          )
          : await ClinicvuService.createPractitioner(
            firstName,
            lastName,
            currentOrganization,
            email,
            role.value || role,
            selectedCarePlans
          )
        if (res.status !== 200) {
          throw new Error('Was unable to save practitioner information')
        }
        // Handle successful save, e.g., show a success message or update state
      } catch (error) {
        console.error(error)
      }
    }
  }

  const handleToggleCarePlan = (carePlan) => {
    if (selectedCarePlans.includes(carePlan.id)) {
      setCarePlanToBeUnselected(carePlan)
    } else {
      const newSelectedCarePlans = [...selectedCarePlans, carePlan.id]
      setSelectedCarePlans(newSelectedCarePlans)
    }
  }

  const unselectCarePlan = (carePlan) => {
    const carePlanIndex = selectedCarePlans.indexOf(carePlan.id)
    const newSelectedCarePlans = [...selectedCarePlans]
    newSelectedCarePlans.splice(carePlanIndex, 1)
    setSelectedCarePlans(newSelectedCarePlans)
    setCarePlanToBeUnselected(null)
  }

  return (
    <>
      <div className={styles['register-practitioner']}>
        <form className={styles['register-practitioner__form']}>
          <div className={styles.row}>
            <label className={styles['single-wide']}>
              <TextField
                label="Email*"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                size="small"
                disabled={!!practitioner}
                error={emailInvalid}
                helperText={emailInvalid ? 'Please enter a valid email' : ''}
              />
            </label>
          </div>

          <div className={styles.row}>
            <label className={styles['double-wide']}>
              <TextField
                label="First name*"
                variant="outlined"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                size="small"
                disabled={!!practitioner}
                error={firstNameInvalid}
                helperText={firstNameInvalid ? 'First name is required' : ''}
              />
            </label>
            <label className={styles['double-wide']}>
              <TextField
                label="Last name*"
                variant="outlined"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                size="small"
                disabled={!!practitioner}
                error={lastNameInvalid}
                helperText={lastNameInvalid ? 'Last name is required' : ''}
              />
            </label>
          </div>

          <div className={styles.row}>
            <label className={styles['single-wide']}>
              <TextField
                label="Role*"
                value={role}
                size="small"
                onChange={(e) => setRole(e.target.value)}
                disabled={!!practitioner}
                error={roleInvalid}
                select
              >
                <MenuItem value="doctor">Doctor</MenuItem>
                <MenuItem value="nurse">Nurse</MenuItem>
                <MenuItem value="ict">Technical</MenuItem>
                <MenuItem value="159483005">Clerical</MenuItem>
              </TextField>
            </label>
          </div>

          <Typography variant="h6" component="div">
            Care Plans
          </Typography>

          <List
            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
          >
            {carePlans?.map((carePlan, index) => {
              const labelId = `care-plan-${carePlan?.title}`

              return (
                <ListItem key={index} disablePadding sx={{ height: '35px' }}>
                  <ListItemButton
                    onClick={() => handleToggleCarePlan(carePlan)}
                    dense
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={selectedCarePlans.includes(carePlan.id)}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                        sx={{ padding: 0 }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      id={labelId}
                      primary={`${carePlan?.title}`}
                      sx={{ marginLeft: '-30px' }}
                    />
                  </ListItemButton>
                </ListItem>
              )
            })}
          </List>

          <div className={`${styles.row} ${styles['row--spaced']}`}>
            <div className={`${styles['btn--margined']}`}></div>
            <div>
              {!practitioner && (
                <button
                  className={`${styles.btn} ${styles['btn--red']}`}
                  onClick={() => btnFunction(null, false)}
                >
                  Cancel
                </button>
              )}
              <button
                className={styles.btn}
                onClick={(e) => savePractitionerInfo(e)}
              >
                {practitioner ? 'Update' : 'Save'}
              </button>
            </div>
          </div>
        </form>
      </div>

      <Modal
        open={!!carePlanToBeUnselected}
        onClose={() => setCarePlanToBeUnselected(null)}
        aria-labelledby="careplans-confirmation-modal"
        aria-describedby="Confirm unselecting a care plan"
      >
        <Box sx={confirmationModalStyle}>
          <Typography variant="h6" component="h2">
            Are you sure?
          </Typography>
          <Typography>
            Unselecting the {`${carePlanToBeUnselected?.name}`} care plan will
            dissociate the practitioner with all the patients on it.
          </Typography>
          <div className={`${styles.row} ${styles['row--spaced']}`}>
            <button
              className={styles.btn}
              onClick={() => setCarePlanToBeUnselected(null)}
            >
              Cancel
            </button>
            <button
              className={`${styles.btn} ${styles['btn--red']}`}
              onClick={() => unselectCarePlan(carePlanToBeUnselected)}
            >
              Unselect Care Plan
            </button>
          </div>
        </Box>
      </Modal>
    </>
  )
}

PractitionerForm.propTypes = {
  btnFunction: PropTypes.func,
  practitioner: PropTypes.object
}

export default PractitionerForm
