import { MenuItem, Select, TextField } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { ParamTimeZones } from '@utils/constants'
import React, { useState } from 'react'
import styles from './AddNewPortalForm.module.scss'
import PropTypes from 'prop-types'
import ClinicvuService from 'services/Clinicvu/Clinicvu.service'

const AddNewPortalForm = ({ setShowNew, goBack, currPortal }) => {
  // Patient Information Data
  const [name, setName] = useState('' || currPortal?.name)
  const [timezone, setTimezone] = useState('' || currPortal?.timezone)
  const [licences, setLicences] = useState(
    '' || currPortal?.biovu_user_licences
  )

  const savePortal = async (e) => {
    e.preventDefault()
    try {
      const res = await ClinicvuService.createPortal(name, licences, timezone)
      if (res.status !== 200) {
        throw new Error('Was unable to save user information with id')
      }
      setShowNew(false)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className={styles.header}>New Portal</div>
        <form className={styles['enrollment-form']}>
          {/* Patient Info */}
          <div className={styles['info-container']}>
            <div className={styles['info-container__row']}>
              <TextField
                label="Portal Name"
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className={styles.email}
                size="small"
                style={{ marginLeft: '15px' }}
                disabled={!!currPortal}
              />
            </div>
            <div className={styles['info-container__row']}>
              <TextField
                label="Patient Licences #"
                variant="outlined"
                value={licences}
                onChange={(e) => setLicences(e.target.value)}
                className={styles['phone-number']}
                size="small"
                style={{ marginLeft: '15px' }}
                disabled={!!currPortal}
              />
            </div>
            <div className={styles['info-container__row']}>
              <label className={styles['date-of-birth']}>
                Timezone
                <Select
                  value={timezone}
                  onChange={(e) => setTimezone(e.target.value)}
                  className={styles.param__input}
                  disabled={!!currPortal}
                >
                  {Object.keys(ParamTimeZones).map((timezone) => {
                    return (
                      <MenuItem key={timezone} value={timezone}>
                        {ParamTimeZones[timezone]}
                      </MenuItem>
                    )
                  })}
                </Select>
              </label>
            </div>
            <div
              className={`${styles['info-container__row']} ${styles['btns-row']}`}
            >
              <div>
                <button
                  onClick={goBack}
                  className={`${styles['enrollment-form__btn']} ${styles.cancel}`}
                >
                  Cancel
                </button>
                <button
                  onClick={(e) => savePortal.mutate(e)}
                  type="submit"
                  className={styles['enrollment-form__btn']}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </LocalizationProvider>
    </div>
  )
}

AddNewPortalForm.propTypes = {
  setShowNew: PropTypes.func,
  goBack: PropTypes.func,
  currPortal: PropTypes.object
}

export default AddNewPortalForm
