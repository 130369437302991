import React, { useState, useEffect } from 'react'
import {
  Box,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
  Typography
} from '@mui/material'
import styles from './MeasurementsForm.module.scss'
import ClinicvuService from 'services/Clinicvu/Clinicvu.service'
import { useNotification } from '@context/NotificationContext'

const measurementTypes = [
  { code: '56342008', label: 'Temperature' },
  { code: '135840009', label: 'Blood Pressure' },
  { code: '252465000', label: 'SPO2' },
  { code: '29303009', label: 'ECG' },
  { code: '308113006', label: 'Blood Glucose' },
  { code: '225171007', label: 'Weight' },
  { code: '445988008', label: 'Questionnaire' },
  { code: '445283009', label: 'Review Files' }
]

const MeasurementsForm = ({
  activity,
  index,
  handleActivityChange,
  removeActivity,
  carePlan,
  files,
  questionnaires
}) => {
  const [code, setCode] = useState('')
  const [frequency, setFrequency] = useState(1)
  const [period, setPeriod] = useState('d')
  const [questionnaire, setQuestionnaire] = useState('')
  const [file, setFile] = useState('')
  const [goalType, setGoalType] = useState('none')
  const [absoluteMin, setAbsoluteMin] = useState('')
  const [absoluteMax, setAbsoluteMax] = useState('')
  const [relativeChange, setRelativeChange] = useState('')
  const { showNotification } = useNotification()
  const [relativeChangeDuration, setRelativeChangeDuration] = useState('')
  const [relativeChangeDurationUnit, setRelativeChangeDurationUnit] =
    useState('d')
  const [systolicMin, setSystolicMin] = useState('')
  const [systolicMax, setSystolicMax] = useState('')
  const [diastolicMin, setDiastolicMin] = useState('')
  const [diastolicMax, setDiastolicMax] = useState('')

  console.log('activity', activity)

  const fetchGoal = async (reference) => {
    try {
      const res = await ClinicvuService.getGoalById(reference)
      const goal = res.data
      const target = goal.target?.[0]?.detailQuantity || {}

      setGoalType(goal.type?.coding?.[0]?.code || 'none')
      setAbsoluteMin(target.low || '')
      setAbsoluteMax(target.high || '')
      setRelativeChange(target.value || '')
      setRelativeChangeDuration(target.duration || '')
      setRelativeChangeDurationUnit(target.durationUnit || 'd')
    } catch (e) {
      showNotification(`Error fetching goal: ${e.message}`, 'error')
    }
  }

  useEffect(() => {
    if (activity) {
      setCode(activity.detail.code?.coding?.[0]?.code || '')
      setFrequency(activity.detail.scheduledTiming?.repeat?.frequency || 1)
      setPeriod(activity.detail.scheduledTiming?.repeat?.periodUnit || 'd')
      if (activity.detail.extension) {
        const documentExtension = activity.detail.extension.find(
          (ext) =>
            ext.url ===
            'http://example.org/fhir/StructureDefinition/document-reference'
        )
        if (documentExtension) {
          const documentReference =
            documentExtension.valueReference.reference || ''
          const documentId = documentReference.split('/').pop()
          setFile(documentId)
        }

        const questionnaireExtension = activity.detail.extension.find(
          (ext) =>
            ext.url ===
            'http://example.org/fhir/StructureDefinition/questionnaire-reference'
        )
        if (questionnaireExtension) {
          const questionnaireReference =
            questionnaireExtension.valueReference.reference || ''
          const questionnaireId = questionnaireReference.split('/').pop()
          setQuestionnaire(questionnaireId)
        }
      }
      if (activity.detail.goal?.[0]?.reference) {
        fetchGoal(activity.detail.goal[0].reference)
      } else if (activity.detail.goal?.[0]?.target?.[0]?.detailRange) {
        const target = activity.detail.goal[0].target[0].detailRange
        setGoalType('absolute')
        if (activity.detail.code?.coding?.[0]?.code === '135840009') {
          setSystolicMin(target.low?.value || '')
          setSystolicMax(target.high?.value || '')
          if (activity.detail.goal[0].target[1]?.detailRange) {
            const diastolicTarget =
              activity.detail.goal[0].target[1].detailRange
            setDiastolicMin(diastolicTarget.low?.value || '')
            setDiastolicMax(diastolicTarget.high?.value || '')
          }
        } else {
          setAbsoluteMin(target.low?.value || '')
          setAbsoluteMax(target.high?.value || '')
        }
      } else if (activity.detail.goal?.[0]?.target?.[0]?.detailQuantity) {
        const target = activity.detail.goal[0].target[0].detailQuantity
        setGoalType('relative')
        setRelativeChange(target.value || '')
        setRelativeChangeDuration(target.dueDuration?.value || '')
        setRelativeChangeDurationUnit(target.dueDuration?.unit || 'd')
      } else {
        setGoalType('none')
      }
    }
  }, [activity])

  const handleGoalTypeChange = (e) => {
    const newGoalType = e.target.value
    setGoalType(newGoalType)
    let goal = {}

    if (newGoalType === 'absolute') {
      if (code === '135840009') {
        // Blood Pressure
        goal = {
          target: [
            {
              measure: { coding: [{ code }] },
              detailRange: {
                low: { value: systolicMin },
                high: { value: systolicMax }
              }
            },
            {
              measure: { coding: [{ code }] },
              detailRange: {
                low: { value: diastolicMin },
                high: { value: diastolicMax }
              }
            }
          ]
        }
      } else {
        goal = {
          target: [
            {
              measure: { coding: [{ code }] },
              detailRange: {
                low: { value: absoluteMin },
                high: { value: absoluteMax }
              }
            }
          ]
        }
      }
    } else if (newGoalType === 'relative') {
      goal = {
        target: [
          {
            measure: { coding: [{ code }] },
            detailQuantity: {
              unit: '%',
              value: relativeChange
            },
            dueDuration: {
              value: relativeChangeDuration,
              unit: relativeChangeDurationUnit
            }
          }
        ]
      }
    }
    handleActivityChange([goal], index, 'detail.goal')
  }

  const getFileExtension = (type, selectedId) => {
    const urlMap = {
      document:
        'http://example.org/fhir/StructureDefinition/document-reference',
      questionnaire:
        'http://example.org/fhir/StructureDefinition/questionnaire-reference'
    }

    return [
      {
        url: urlMap[type],
        valueReference: {
          reference: `${type === 'document' ? 'DocumentReference' : 'Questionnaire'}/${selectedId}`
        }
      }
    ]
  }

  return (
    <Box className={styles.row} component="form" sx={{ p: 4 }}>
      <Grid container spacing={3}>
        {/* Action Selection */}
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="action-label">Select Measurement</InputLabel>
            <Select
              labelId="action-label"
              value={code}
              onChange={(e) =>
                handleActivityChange(
                  e.target.value,
                  index,
                  'detail.code.coding.0.code'
                )
              }
              label="Select Measurement"
              required
              disabled={carePlan !== null}
            >
              {measurementTypes.map((type) => (
                <MenuItem key={type.code} value={type.code}>
                  {type.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Frequency */}
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              label="Frequency"
              type="number"
              value={frequency}
              onChange={(e) =>
                handleActivityChange(
                  Number(e.target.value),
                  index,
                  'detail.scheduledTiming.repeat.frequency'
                )
              }
              required
              disabled={carePlan !== null}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="period-label">Period</InputLabel>
            <Select
              labelId="period-label"
              value={period}
              onChange={(e) =>
                handleActivityChange(
                  e.target.value,
                  index,
                  'detail.scheduledTiming.repeat.periodUnit'
                )
              }
              label="Period"
              disabled={carePlan !== null}
            >
              <MenuItem value="d">Day</MenuItem>
              <MenuItem value="wk">Week</MenuItem>
              <MenuItem value="mo">Month</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* Questionnaire Assignment */}
        {code === '445988008' && (
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="questionnaire-label">
                Assign Questionnaire
              </InputLabel>
              <Select
                labelId="questionnaire-label"
                value={questionnaire}
                onChange={(e) => {
                  const selectedQuestionnaireId = e.target.value
                  const questionnaireExtension = getFileExtension(
                    'questionnaire',
                    selectedQuestionnaireId
                  )
                  handleActivityChange(
                    questionnaireExtension,
                    index,
                    'detail.extension'
                  )
                }}
                label="Assign Questionnaire"
                disabled={carePlan !== null}
              >
                {questionnaires?.map((q) => (
                  <MenuItem key={q.id} value={q.id}>
                    {q.title || q.name[0].text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}

        {/* File Upload */}
        {code === '445283009' && (
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="file-label">Select File</InputLabel>
              <Select
                labelId="file-label"
                value={file}
                onChange={(e) => {
                  const selectedFileId = e.target.value
                  const fileExtension = getFileExtension(
                    'document',
                    selectedFileId
                  )
                  handleActivityChange(fileExtension, index, 'detail.extension')
                }}
                label="Select File"
                disabled={carePlan !== null}
              >
                {files?.map((file) => (
                  <MenuItem key={file.id} value={file.id}>
                    {file.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}

        {/* Goal Section */}
        {code !== '445988008' &&
          code !== '445283009' &&
          code !== '29303009' && (
            <Grid item xs={12}>
              <Typography variant="h6">Define Alerts</Typography>
              <FormControl fullWidth>
                <InputLabel id="goal-type-label">Change Type</InputLabel>
                <Select
                  labelId="goal-type-label"
                  value={goalType}
                  onChange={handleGoalTypeChange}
                  disabled={carePlan !== null}
                >
                  <MenuItem value="none">None</MenuItem>
                  <MenuItem value="absolute">Absolute</MenuItem>
                  <MenuItem value="relative">Relative</MenuItem>
                </Select>
              </FormControl>
              {goalType === 'absolute' && (
                <Box mt={2}>
                  {code === '135840009'
                    ? (
                    <>
                      <TextField
                        label="Systolic Min"
                        value={systolicMin}
                        onChange={(e) =>
                          handleActivityChange(
                            Number(e.target.value),
                            index,
                            'detail.goal.0.target.0.detailRange.low.value'
                          )
                        }
                        type="number"
                        fullWidth
                        sx={{ mb: 2 }}
                        disabled={carePlan !== null}
                      />
                      <TextField
                        label="Systolic Max"
                        value={systolicMax}
                        onChange={(e) =>
                          handleActivityChange(
                            Number(e.target.value),
                            index,
                            'detail.goal.0.target.0.detailRange.high.value'
                          )
                        }
                        type="number"
                        fullWidth
                        sx={{ mb: 2 }}
                        disabled={carePlan !== null}
                      />
                      <TextField
                        label="Diastolic Min"
                        value={diastolicMin}
                        onChange={(e) =>
                          handleActivityChange(
                            Number(e.target.value),
                            index,
                            'detail.goal.0.target.1.detailRange.low.value'
                          )
                        }
                        type="number"
                        fullWidth
                        sx={{ mb: 2 }}
                        disabled={carePlan !== null}
                      />
                      <TextField
                        label="Diastolic Max"
                        value={diastolicMax}
                        onChange={(e) =>
                          handleActivityChange(
                            Number(e.target.value),
                            index,
                            'detail.goal.0.target.1.detailRange.high.value'
                          )
                        }
                        type="number"
                        fullWidth
                        disabled={carePlan !== null}
                      />
                    </>
                      )
                    : (
                    <>
                      <TextField
                        label="Absolute Min"
                        value={absoluteMin}
                        onChange={(e) =>
                          handleActivityChange(
                            Number(e.target.value),
                            index,
                            'detail.goal.0.target.0.detailRange.low.value'
                          )
                        }
                        type="number"
                        fullWidth
                        sx={{ mb: 2 }}
                        disabled={carePlan !== null}
                      />
                      <TextField
                        label="Absolute Max"
                        value={absoluteMax}
                        onChange={(e) =>
                          handleActivityChange(
                            Number(e.target.value),
                            index,
                            'detail.goal.0.target.0.detailRange.high.value'
                          )
                        }
                        type="number"
                        fullWidth
                        disabled={carePlan !== null}
                      />
                    </>
                      )}
                </Box>
              )}
              {goalType === 'relative' && (
                <Box mt={2}>
                  <TextField
                    label="Relative Change (%)"
                    value={relativeChange}
                    onChange={(e) =>
                      handleActivityChange(
                        e.target.value,
                        index,
                        'detail.goal.0.target.0.detailQuantity.value'
                      )
                    }
                    type="number"
                    fullWidth
                    sx={{ mb: 2 }}
                    disabled={carePlan !== null}
                  />
                  <TextField
                    label="Duration"
                    value={relativeChangeDuration}
                    onChange={(e) =>
                      handleActivityChange(
                        e.target.value,
                        index,
                        'detail.goal.0.target.0.dueDuration.value'
                      )
                    }
                    type="number"
                    fullWidth
                    sx={{ mb: 2 }}
                    disabled={carePlan !== null}
                  />
                  <FormControl fullWidth>
                    <InputLabel id="duration-unit-label">
                      Duration Unit
                    </InputLabel>
                    <Select
                      labelId="duration-unit-label"
                      value={relativeChangeDurationUnit}
                      onChange={(e) =>
                        handleActivityChange(
                          e.target.value,
                          index,
                          'detail.goal.0.target.0.dueDuration.unit'
                        )
                      }
                      label="Duration Unit"
                      disabled={carePlan !== null}
                    >
                      <MenuItem value="d">Day</MenuItem>
                      <MenuItem value="wk">Week</MenuItem>
                      <MenuItem value="mo">Month</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              )}
            </Grid>
        )}
        {!carePlan && (
          <Grid item xs={12}>
            <button onClick={removeActivity}>Remove</button>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default MeasurementsForm
