import React, { useEffect, useReducer, useState } from 'react'

import 'react-dropdown/style.css'
import styles from './SurveyForm.module.scss'
import {
  Alert,
  Box,
  Button,
  MenuItem,
  Modal,
  Select,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'

import PropTypes from 'prop-types'

import ClinicvuService from 'services/Clinicvu/Clinicvu.service'

const questionToMultipleChoiceModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  gap: '20px'
}

const mcButtonStyle = {
  borderRadius: '50%',
  width: '60px',
  height: '60px',
  fontSize: '140%',
  backgroundColor: '#2196f3'
}

const sortItemsByNumber = (items) => {
  return items.sort((a, b) => a.linkId - b.linkId)
}

const surveyReducer = (state, action) => {
  switch (action.type) {
    case 'init-survey': {
      return {
        title: action.payload.title,
        item: sortItemsByNumber(action.payload.item)
      }
    }
    case 'set-title': {
      return { ...state, title: action.payload }
    }
    case 'set-item': {
      const newItem = [...state.item]
      const { value, index, param } = action.payload
      newItem[index][param] = value

      // Initialize Likert scale options if type is 'choice'
      if (
        param === 'type' &&
        value === 'choice' &&
        newItem[index].answerOption.length === 0
      ) {
        newItem[index].answerOption = [
          { valueString: 'Strongly Disagree' },
          { valueString: 'Disagree' },
          { valueString: 'Neutral' },
          { valueString: 'Agree' },
          { valueString: 'Strongly Agree' }
        ]
      }

      return { ...state, item: sortItemsByNumber(newItem) }
    }
    case 'set-item-choice': {
      const { question, value, index } = action.payload
      const newItem = [...state.item]
      newItem[question.index].answerOption[index].valueString = value
      return { ...state, item: sortItemsByNumber(newItem) }
    }
    case 'add-new-question': {
      action.payload.preventDefault()
      const newItem = [
        ...state.item,
        {
          linkId: state.item.length + 1, // Set linkId to the next number
          text: '',
          type: 'boolean',
          answerOption: []
        }
      ]
      return { ...state, item: newItem } // No need to sort items
    }
    case 'remove-last-mc-option': {
      const newItem = [...state.item]
      newItem[action.payload.index].answerOption.pop()
      return { ...state, item: sortItemsByNumber(newItem) }
    }
    case 'add-mc-option': {
      const newItem = [...state.item]
      newItem[action.payload.index].answerOption.push({
        valueString: ''
      })
      return { ...state, item: sortItemsByNumber(newItem) }
    }
    default:
      return state
  }
}

const SurveyForm = ({ btnFunction, survey, portal, fetchAction }) => {
  const [questionToMultipleChoice, setQuestionToMultipleChoice] = useState(null)

  const [surveyData, dispatch] = useReducer(surveyReducer, {
    title: '',
    item: []
  })

  console.log('surveyData', surveyData)

  const openMCModal = (e, question, index) => {
    e.preventDefault()
    setQuestionToMultipleChoice({ ...question, index })
  }

  const initializeSurvey = async () => {
    try {
      dispatch({
        type: 'init-survey',
        payload: { item: survey.item, title: survey.title }
      })
    } catch (e) {
      console.error(`Error while fetching survey data: ${e}`)
    }
  }

  useEffect(() => {
    if (survey) {
      initializeSurvey()
    }
  }, [survey])

  const findFormError = () => {
    const questionNumbers = surveyData.item.map((question) => question.linkId)
    const questionText = surveyData.item.map((question) => question.text)
    if (!surveyData.title || surveyData.title.length === 0) {
      return 'Some of the required fields are missing/incorrect. Make sure that you have entered all the information correctly.'
    } else if (questionText.some((item) => item === '')) {
      return 'One or more of your questions are blank.'
    } else if (new Set(questionNumbers).size !== questionNumbers.length) {
      return "There's an error in your numbering. Make sure each question is numbered correctly and there are no duplicates."
    } else if (surveyData.item.length === 0) {
      return 'Your survey must have at least one question.'
    } else return ''
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    const error = findFormError()
    if (error.length === 0) {
      try {
        console.log(surveyData)
        await ClinicvuService.createQuestionnaire(
          portal,
          surveyData.title,
          surveyData.item,
          'english'
        )
        btnFunction(null, false)
        fetchAction()
      } catch (e) {
        console.error(`Error occured when posting survey information: ${e}`)
      }
    } else {
      alert(error)
    }
  }

  const renderQuestionField = (question, index) => {
    switch (question.type) {
      case 'choice':
        return (
          <div className={styles['question-choices']}>
            <div>
              {question.answerOption?.map((choice, index) => (
                <p key={`question-choice-${index}`}>{choice.valueString}</p>
              ))}
            </div>
            {survey === null && (
              <Tooltip title={survey === null ? 'Edit Question Options' : ''}>
                <span className={styles['question-choices__span']}>
                  <button
                    disabled={survey !== null}
                    className={styles['question-choices__btn']}
                    onClick={(e) => openMCModal(e, question, index)}
                  >
                    <img src="/assets/svgs/pencil.svg" alt="Edit" />
                  </button>
                </span>
              </Tooltip>
            )}
          </div>
        )
      // Add more cases for other FHIR types as needed
      default:
        return null
    }
  }

  return (
    <div>
      <div className={styles.header}>
        {survey ? 'View Survey' : 'Define Survey'}
      </div>
      {surveyData && (
        <form className={styles.form}>
          <div className={styles.row}>
            <TextField
              disabled={survey !== null}
              label="Survey Name"
              required
              value={surveyData.title}
              size="small"
              onChange={(e) =>
                dispatch({ type: 'set-title', payload: e.target.value })
              }
              sx={{ marginLeft: '15px' }}
            />
          </div>
          {surveyData.item.map((question, index) => (
            <React.Fragment key={`question-${index}`}>
              <div className={styles.row}>
                <Select
                  disabled={survey !== null}
                  size="small"
                  labelId="answer-type-dropdown"
                  value={question.linkId}
                  onChange={(e) =>
                    dispatch({
                      type: 'set-item',
                      payload: {
                        value: e.target.value,
                        index,
                        param: 'linkId'
                      }
                    })
                  }
                  sx={{ marginLeft: '15px' }}
                  className={styles.row__number}
                >
                  {surveyData.item.map((content, i) => {
                    return (
                      <MenuItem key={i} value={i + 1}>
                        {i + 1}
                      </MenuItem>
                    )
                  })}
                </Select>

                <TextField
                  disabled={survey !== null}
                  label="Question Text"
                  value={question.text}
                  size="small"
                  onChange={(e) =>
                    dispatch({
                      type: 'set-item',
                      payload: {
                        value: e.target.value,
                        index,
                        param: 'text'
                      }
                    })
                  }
                  sx={{ marginLeft: '15px' }}
                  className={styles.row__question}
                />
                <Select
                  disabled={survey !== null}
                  size="small"
                  labelId="answer-type-dropdown"
                  value={question.type}
                  onChange={(e) =>
                    dispatch({
                      type: 'set-item',
                      payload: {
                        value: e.target.value,
                        index,
                        param: 'type'
                      }
                    })
                  }
                  className={styles.row__type}
                >
                  <MenuItem value={'boolean'}>Yes or No</MenuItem>
                  <MenuItem value={'string'}>Text (One Line)</MenuItem>
                  <MenuItem value={'text'}>Long Text (Multiline)</MenuItem>
                  <MenuItem value={'choice'}>Multiple Choice</MenuItem>
                  <MenuItem value={'decimal'}>Decimal</MenuItem>
                  <MenuItem value={'integer'}>Integer</MenuItem>
                  <MenuItem value={'date'}>Date</MenuItem>
                  <MenuItem value={'dateTime'}>Date and Time</MenuItem>
                  <MenuItem value={'time'}>Time</MenuItem>
                  <MenuItem value={'attachment'}>
                    Upload File (Image / Video)
                  </MenuItem>
                </Select>
                {renderQuestionField(question, index)}
              </div>
            </React.Fragment>
          ))}
          <div className={styles.btns}>
            {!survey
              ? (
              <button
                className={styles['btn-primary']}
                onClick={(e) => {
                  dispatch({ type: 'add-new-question', payload: e })
                }}
              >
                Add a new field
              </button>
                )
              : null}
            <div>
              <button
                className={styles['btn-cancel']}
                onClick={() => btnFunction(null, false)}
              >
                Cancel
              </button>
              {!survey ? <button onClick={handleSubmit}>Save</button> : null}
            </div>
          </div>
        </form>
      )}

      {questionToMultipleChoice && (
        <Modal
          open={!!questionToMultipleChoice}
          onClose={() => setQuestionToMultipleChoice(null)}
          aria-labelledby="Survey Multiple Choice Options Modal"
          aria-describedby="This modal allows the clinician to select the number of multiple choice options and their exact specifications"
        >
          <Box sx={questionToMultipleChoiceModalStyle}>
            <Typography variant="h4" id="survey-mc__header">
              Select the number of options
            </Typography>

            <div className={styles['mcModal__num-questions']}>
              <Button
                variant="contained"
                sx={mcButtonStyle}
                disabled={questionToMultipleChoice.answerOption.length <= 2}
                onClick={() =>
                  dispatch({
                    type: 'remove-last-mc-option',
                    payload: questionToMultipleChoice
                  })
                }
              >
                -
              </Button>
              <p>{questionToMultipleChoice.answerOption.length}</p>
              <Button
                variant="contained"
                sx={mcButtonStyle}
                disabled={questionToMultipleChoice.answerOption.length >= 6}
                onClick={() =>
                  dispatch({
                    type: 'add-mc-option',
                    payload: questionToMultipleChoice
                  })
                }
              >
                +
              </Button>
            </div>

            {questionToMultipleChoice?.answerOption?.map((choice, index) => (
              <TextField
                key={`question-option-text-${index}`}
                variant="outlined"
                value={choice.valueString}
                onChange={(e) =>
                  dispatch({
                    type: 'set-item-choice',
                    payload: {
                      question: questionToMultipleChoice,
                      value: e.target.value,
                      index
                    }
                  })
                }
              />
            ))}

            <Alert severity="info">A maximum of 6 options can be created</Alert>

            <div className={styles['mcModal__num-questions']}>
              <Button
                variant="contained"
                sx={{ backgroundColor: '#777' }}
                onClick={() => setQuestionToMultipleChoice(null)}
              >
                Close
              </Button>
              {/* <Button variant="contained" sx={{ backgroundColor: "#2196f3" }}>
                Save
              </Button> */}
            </div>
          </Box>
        </Modal>
      )}
    </div>
  )
}

SurveyForm.propTypes = {
  btnFunction: PropTypes.func,
  survey: PropTypes.any,
  portal: PropTypes.string,
  fetchAction: PropTypes.func
}

export default SurveyForm
