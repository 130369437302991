import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import Alerts from '../components/pages/alerts/Alerts'
import CarePlans from '../components/pages/care-plans/CarePlans'
import CarePlanDetails from '@pages/care-plan-details/CarePlanDetails'
import Clinic from '../components/pages/clinic/Clinic'
import Home from '../components/pages/home/Home'
import Questionaires from '../components/pages/questionaires/Questionaires'
import PatientDetails from '../components/pages/patient-details/PatientDetails'
import SignOut from '../components/shared/sign-out/SignOut'
import Portals from '@pages/portals/Portals'
import Sessions from '@pages/sessions/Sessions'
import Files from '@pages/Files/Files'
import FileDetails from '@pages/file-details/FileDetails'
import PractitionerDetails from '@pages/practitioner-details/PractitionerDetails'
import QuestionnaireDetails from '@pages/questionnaire-details/QuestionnaireDetails'

const ClinicvuRoutes = () => {
  return (
    <Routes>
      <Route path="/" exact element={<Navigate replace to="patients" />} />
      <Route path="/patients" element={<Home />} />
      <Route path="/patient/:patientID" exact element={<PatientDetails />} />
      <Route path="/alerts" exact element={<Alerts />} />
      <Route path="/care-plans" exact element={<CarePlans />} />
      <Route
        path="/care-plans/:carePlanId"
        exact
        element={<CarePlanDetails />}
      />
      <Route path="/questionnaires" exact element={<Questionaires />} />
      <Route
        path="/questionnaires/:questionnaireId"
        exact
        element={<QuestionnaireDetails />}
      />
      <Route path="/files" exact element={<Files />} />
      <Route path="/files/:fileId" exact element={<FileDetails />} />
      <Route path="/clinic" exact element={<Clinic />} />
      <Route
        path="/practitioner/:practitionerId"
        exact
        element={<PractitionerDetails />}
      />
      <Route path="/session" exact element={<Sessions />} />
      <Route path="/portals" exact element={<Portals />} />
      <Route path="/sign-out" exact element={<SignOut />} />
    </Routes>
  )
}

export default ClinicvuRoutes
